import React from 'react'
import Layout from '../components/layout'




class ContactIndex extends React.Component {
  render() {


    return (
      <Layout title={"Contact"} location={this.props.location}>
        <div className='empty_box'></div>
        <h1 style={{textAlign: 'center'}}>Contact</h1>
        <form className='contact_form_container' action="#" method="post">
          <h2>Contactformulier</h2>
          <div className='row full_width'>
            <div className='column contact_input_container' style={{width: '45%'}}>
              <label for="name">Naam*</label>
              <input className='contact_input_field' name="name" id="name" type="text" placeholder="Naam"></input>
            </div>
            <div style={{width: '5%'}}></div>
            <div className='column contact_input_container' style={{width: '50%'}} >
              <label for="email">Email*</label>
              <input className='contact_input_field' name="email" id="email" type="email" placeholder="Email"></input>
            </div>
          </div>
          <div className='column full_width contact_input_container' >
            <label for="phone_number">Telefoonnummer*</label>
            <input className='contact_input_field' style={{width: '45%'}} name="phone_number" id="phone_number" type="text" placeholder="Telefoonnummer"></input>
          </div>
          <div className='column full_width contact_input_container' >
            <label for="message">Bericht*</label>
            <textarea  className='contact_input_field'name="message" id="message" rows="6" placeholder="Bericht"></textarea>
          </div>
            <div id="send_btn_container">
              <input value="Stuur bericht" className="button special send_btn"></input>
          </div>
        <div style={{width: '100%', marginTop: '100px'}}>
          <a href="mailto:info@timestables.nl">Email: info@timestables.nl</a>
          <p>Adres: Achterweg 33 - 2376 AX Nieuwe Wetering</p>
          <a href="tel:0713314241">Tel: 071-3314241</a>
        </div>
        </form>
        <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: '100px'}}>
					<iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2444.7514478314342!2d4.614541616470309!3d52.21156417975698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5c339e02b2a9d%3A0x597d501907d759c8!2sAchterweg%2033%2C%202376%20AX%20Nieuwe%20Wetering!5e0!3m2!1snl!2snl!4v1638110265988!5m2!1snl!2snl" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
				</div>
      </Layout>
    )
  }
}

export default ContactIndex


